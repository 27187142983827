import Moon from '../../assets/Moon.svg'
import Sun from '../../assets/Sun.svg'
import Logo from 'components/common/Logo'
import OpenInNewIcon from 'components/Icons/OpenInNewIcon'
import AppContext from 'context/AppContext'
import moment from 'moment'
import Avatar from 'react-avatar'
import { Container, Nav, Navbar } from 'react-bootstrap'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from 'react-router-dom'
import Routes from 'routes'
import React, { useContext } from 'react'
import './TopNav.scss'
import QuickSightTopic from '../../Reporting/BusinessIntelligence/components/QuickSightTopic'

const TopNav = ({ isAuthenticated, lastRunTime }) => {
  const { version, packagingType, theme, setTheme, isQEnabled } = useContext(AppContext)
  const userName = localStorage.getItem('username')

  const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname.endsWith('.app')

  const switchTheme = () => {
    const theme = document.documentElement.getAttribute('data-bs-theme')
    if (theme === 'dark') {
      document.documentElement.setAttribute('data-bs-theme', 'light')
      setTheme('light')
      localStorage.setItem('app-theme', 'light')
    } else {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
      setTheme('dark')
      localStorage.setItem('app-theme', 'dark')
    }
  }

  return (
    <header className="sticky-top">
      <Navbar className="top-nav">
        <Container>
          <div className="col-md-2">
            <Navbar.Brand href={Routes.DASHBOARD.PATH}>
              <Logo height={40} alt={Routes.DASHBOARD.TITLE} />
            </Navbar.Brand>
            <Navbar.Toggle />
          </div>
          {isAuthenticated && (
            <>
              <div className="col-md-8">
                <div className="position-relative w-100">{isQEnabled && !isLocalhost && <QuickSightTopic />}</div>
              </div>
              <div className="col-md-2">
                <div className="display">
                  <Navbar.Collapse className="gap-3 justify-content-end">
                    <Navbar.Text className="info-avatar text-end">
                      <span>Welcome {userName}</span>
                      <span>
                        {lastRunTime &&
                          `AWS spend as of ${moment.utc(lastRunTime).local().format('MM/DD/yyyy hh:mm A')}`}
                      </span>
                    </Navbar.Text>

                    <NavDropdown title={<Avatar name={userName} size="40" round={true} />} className="nav-menu">
                      <section className="info-app">
                        <a
                          href="https://docs.finopscenter.com/v1/docs/end-user-documentation"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Documentation <OpenInNewIcon className="nav-menu-icon" />
                        </a>
                        <p>Release v{version}</p>
                        <p>Version: {packagingType}</p>
                      </section>
                      <NavDropdown.Divider />

                      <button className="theme-button" onClick={switchTheme}>
                        {theme === 'dark' ? (
                          <>
                            <img alt="light mode" src={Sun} />
                            Light mode
                          </>
                        ) : (
                          <>
                            <img alt="dark mode" src={Moon} />
                            Dark mode
                          </>
                        )}
                      </button>

                      <NavDropdown.Divider />
                      <Nav.Item key={Routes.LOGOUT.PATH} className="dropdown-item">
                        <Link to={Routes.LOGOUT.PATH}>Logout</Link>
                      </Nav.Item>
                    </NavDropdown>
                  </Navbar.Collapse>
                </div>
              </div>
            </>
          )}
        </Container>
      </Navbar>
    </header>
  )
}

export default TopNav
