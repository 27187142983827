import { useFormik } from 'formik'
import ERROR_MESSAGES from 'utils/errorMessages'
import { useEffect } from 'react'

const validate = (values) => {
  const errors = {}

  if (values.enableThemes) {
    if (!values.darkThemeId) {
      errors.darkThemeId = ERROR_MESSAGES.REQUIRED
    }
    if (!values.lightThemeId) {
      errors.lightThemeId = ERROR_MESSAGES.REQUIRED
    }
  }

  return errors
}

const ThemeForm = ({ formEl, initialThemes }) => {
  const formik = useFormik({
    initialValues: {
      enableThemes: initialThemes?.darkThemeId ? true : false,
      darkThemeId: initialThemes?.darkThemeId || '',
      lightThemeId: initialThemes?.lightThemeId || ''
    },
    validate,
    validateOnMount: true,
    onSubmit: () => {
      formEl.current = formik
    }
  })

  useEffect(() => {
    formEl.current = formik
  }, [formEl, formik])

  useEffect(() => {
    if (initialThemes) {
      formik.setFieldValue('darkThemeId', initialThemes.darkThemeId || '')
      formik.setFieldValue('lightThemeId', initialThemes.lightThemeId || '')
      formik.setFieldValue('enableThemes', initialThemes.darkThemeId ? true : false)
    }
  }, [initialThemes])

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <div className="mb-3">
        <div className="form-check mb-3">
          <input
            id="enableThemes"
            name="enableThemes"
            className="form-check-input"
            type="checkbox"
            onChange={(e) => {
              formik.setFieldValue('enableThemes', e.target.checked)
              if (!e.target.checked) {
                formik.setFieldValue('darkThemeId', '')
                formik.setFieldValue('lightThemeId', '')
              }
            }}
            checked={formik.values.enableThemes}
          />
          <label className="form-check-label" htmlFor="enableThemes">
            Enable Theme Selection
          </label>
        </div>

        {formik.values.enableThemes && (
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label>Dark Theme ID</label>
                <input
                  name="darkThemeId"
                  className={`form-control ${
                    formik.touched.darkThemeId && formik.errors.darkThemeId ? 'has-error' : ''
                  }`}
                  placeholder="Enter Dark Theme ID"
                  value={formik.values.darkThemeId}
                  onChange={formik.handleChange}
                />
                {formik.touched.darkThemeId && formik.errors.darkThemeId && (
                  <div className="text-error">{formik.errors.darkThemeId}</div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <label>Light Theme ID</label>
                <input
                  name="lightThemeId"
                  className={`form-control ${
                    formik.touched.lightThemeId && formik.errors.lightThemeId ? 'has-error' : ''
                  }`}
                  placeholder="Enter Light Theme ID"
                  value={formik.values.lightThemeId}
                  onChange={formik.handleChange}
                />
                {formik.touched.lightThemeId && formik.errors.lightThemeId && (
                  <div className="text-error">{formik.errors.lightThemeId}</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  )
}

export default ThemeForm
