import CustomToggle from '../Toggle/CustomToggle'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import private_routes from 'config/private_routes'
import AppContext from 'context/AppContext'
import { Dropdown, Navbar } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Routes from 'routes'
import { getAllowedRoutes } from 'utils'
import React from 'react'
import './MenuBar.scss'

const MenuBar = (props) => {
  const { selectedYear, setSelectedYear, availableYears } = React.useContext(AppContext)

  const allowedRoutes = getAllowedRoutes(private_routes)
  const routes = allowedRoutes.map((route) => {
    return (
      <Link to={route.path} className={`${route.activePaths.includes(props.location.pathname) ? ' active' : ''}`}>
        {route.title}
      </Link>
    )
  })

  const showYearSelector = ![Routes.DASHBOARD.PATH, Routes.BUDGET.PATH].includes(window.location.pathname)

  return (
    <Navbar activeKey={props.location.pathname} className="menu-bar my-2">
      <div className="menu-title">
        <FormattedMessage id="menu.title" defaultMessage="Cloud" className="text-uppercase" />
        {showYearSelector && (
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} icon={<FontAwesomeIcon icon={faCalendarAlt} />} id="menu-year-selection">
              {selectedYear}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {availableYears.map((mapYear) => {
                return (
                  <Dropdown.Item
                    key={mapYear}
                    active={mapYear === selectedYear}
                    eventKey={mapYear}
                    onClick={() => {
                      setSelectedYear(mapYear)
                    }}
                  >
                    {mapYear}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="menu-items">{routes}</div>
    </Navbar>
  )
}

export default MenuBar
