import createOrUpdateSpace from '../use-cases/createOrUpdateSpace'
import listSpaces from '../use-cases/listSpaces'
import CreditInfo from './CreditInfo'
import Estimates from './Estimates/Estimates'
import Resources from './Resources/Resources'
import SpacesFilters from './SpacesFilters'
import LoadingSpinner from 'components/common/LoadingSpinner'
import AppContext from 'context/AppContext'
import moment from 'moment'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { Tab as ScrollableTab, Tabs as ScrollableTabs, TabScreen } from 'react-tabs-scrollable'
import { toast } from 'react-toastify'
import { useContext, useEffect, useState } from 'react'
import './Space.scss'

const Spaces = () => {
  const { repoFactory, sorElements, selectedYear } = useContext(AppContext)
  const [isLoading, setIsLoading] = useState(false)
  const [spaces, setSpaces] = useState([])
  const [initialFilters, setInitialFilters] = useState([])
  const [selectedFilters, setSelectedFilters] = useState(null)
  const [estimates, setEstimates] = useState([])
  const [filteredEstimates, setFilteredEstimates] = useState([])
  const [searchAccount, setSearchAccount] = useState('')
  const [activeTab, setActiveTab] = useState(0)
  const [key, setKey] = useState('estimate')

  useEffect(() => {
    return () => {
      localStorage.removeItem('initialFilters')
    }
  }, [])

  useEffect(() => {
    getSpaces()
  }, [selectedYear, repoFactory])

  const onTabClick = (e, index) => {
    setActiveTab(index)
  }

  const getSpaces = () => {
    setIsLoading(true)
    listSpaces(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken')
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: (response) => {
            setIsLoading(false)
            setSpaces(response)
          },
          failure: () => {
            setIsLoading(false)
          }
        }
      }
    )
  }

  useEffect(() => {
    if (spaces.length) {
      const initialFiltersValues = JSON.parse(localStorage.getItem('initialFilters')) || {}
      if (Object.keys(initialFiltersValues).length) {
        setInitialFilters(initialFiltersValues)
      } else {
        const { element1, element2, element3, element4 } = spaces[0]
        setInitialFilters({
          element1,
          element2,
          element3,
          element4
        })
      }
    } else {
      setInitialFilters(null)
    }
  }, [spaces])

  useEffect(() => {
    if (Object.values(selectedFilters || {}).every((val) => val)) {
      loadAccounts()
    } else {
      setEstimates([])
    }
    setKey('estimate')
  }, [selectedFilters])

  const loadAccounts = () => {
    const accounts = spaces.filter((space) => {
      return Object.keys(selectedFilters).every((key) => {
        return selectedFilters[key] === space[key]
      })
    })

    const estimates = accounts.map((account) => account.estimates).flat()

    setEstimates(estimates)
  }

  const save = (account) => {
    delete account.allocation
    const updatedEstimates = estimates.map((estimate) => {
      delete estimate.allocation
      return account.accountId === estimate.accountId ? account : estimate
    })
    setIsLoading(true)
    createOrUpdateSpace(
      {
        year: selectedYear,
        token: localStorage.getItem('authToken'),
        element1: selectedFilters.element1,
        element2: selectedFilters.element2,
        element3: selectedFilters.element3,
        element4: selectedFilters.element4,
        estimates: updatedEstimates
      },
      {
        spacesRepo: repoFactory.spacesRepo(),
        observer: {
          success: () => {
            setIsLoading(false)
            toast.success('Estimates have been successfully updated')
            getSpaces()
          },
          failure: (error) => {
            setIsLoading(false)
            toast.error(error.message)
          }
        }
      }
    )
  }

  useEffect(() => {
    if (searchAccount) {
      const filtered = estimates.filter((estimate) => {
        return (
          estimate.accountId?.toLowerCase().includes(searchAccount.toLowerCase()) ||
          estimate.accountName?.toLowerCase().includes(searchAccount.toLowerCase())
        )
      })
      setFilteredEstimates([...filtered])
      setActiveTab(0)
    } else {
      setFilteredEstimates([...estimates])
      setActiveTab(0)
    }
  }, [estimates, searchAccount])

  return (
    <div className="space-section mb-3">
      <Card>
        <Card.Body>
          {isLoading && <LoadingSpinner />}
          <Card className="mb-3">
            <Card.Body>
              <SpacesFilters
                sorElements={sorElements}
                spaces={spaces}
                initialFilters={initialFilters}
                setSelectedFilters={setSelectedFilters}
              />
            </Card.Body>
          </Card>

          <CreditInfo selectedFilters={selectedFilters} year={selectedYear} repoFactory={repoFactory} />

          <Card>
            <Card.Body>
              {estimates.length === 0 ? (
                <div className="alert alert-info mb-0">No data found</div>
              ) : (
                <>
                  <div className="row mb-3">
                    <div className="col-4">
                      <input
                        className="form-control form-control-sm"
                        style={{ fontSize: '12px' }}
                        placeholder="Search by Account ID or Name"
                        value={searchAccount}
                        onChange={(e) => setSearchAccount(e.target.value)}
                      />
                    </div>
                  </div>
                  {filteredEstimates.length ? (
                    <ScrollableTabs activeTab={activeTab} onTabClick={onTabClick} key={filteredEstimates.length}>
                      {filteredEstimates.map((item, index) => (
                        <ScrollableTab key={item.accountId}>
                          <div style={{ fontSize: '10px' }}>{item.accountName}</div>
                          {item.accountId}
                        </ScrollableTab>
                      ))}
                    </ScrollableTabs>
                  ) : (
                    <div className="text-muted text-sm">
                      No accounts match your search criteria. Please try again with different keywords.
                    </div>
                  )}

                  {filteredEstimates.map((account, index) => {
                    const allocation = account.allocation || []
                    const workloads = account.breakdown?.map((b) => b.reference) || []

                    return (
                      <TabScreen key={account.accountId} activeTab={activeTab} index={index} className="mb-3">
                        <hr />

                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Environment</th>
                                <th>Allocation (Current)</th>
                                <th>Program Participation</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{allocation?.[0]?.environment}</td>
                                <td>
                                  {allocation.map((a, i) => (
                                    <div key={`allocation-${i}`}>
                                      <strong>{a.percentage}%</strong> {a.startDate} - {a.endDate}{' '}
                                      {a.insertedDate &&
                                        `(${moment.utc(a.insertedDate).local().format('MM-DD-YYYY hh:mm:ss A')})`}
                                    </div>
                                  ))}
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <hr />

                        <div className="tabs-section">
                          <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                            <Tab eventKey="estimate" title="Estimates">
                              <Estimates account={account} save={save} />
                            </Tab>
                            <Tab eventKey="resource" title="Resources">
                              <Resources filters={selectedFilters} account={account} workloads={workloads} />
                            </Tab>
                          </Tabs>
                        </div>
                      </TabScreen>
                    )
                  })}
                </>
              )}
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </div>
  )
}
export default Spaces
