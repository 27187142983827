import getAccountNameWithNumber from '../helpers/getAccountNameWithNumber'
import { faArrowDown, faArrowUp, faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import getPaginationControls from 'components/common/Utils/getPaginationControls'
import moment from 'moment-timezone'
import { Col, Row } from 'react-bootstrap'
import React, { Fragment, useEffect, useState } from 'react'

const columnHelper = createColumnHelper()

const currentYear = new Date().getFullYear()

const AccountsWithBudgets = ({
  allAccounts,
  allocationYear,
  accountsWithAllocations,
  sorElements,
  onRemap,
  sorElementMapping
}) => {
  const [data, setData] = useState([])
  const [filteredAccounts, setFilteredAccounts] = useState(accountsWithAllocations)
  const [searchText, setSearchText] = useState('')
  const [sorting, setSorting] = useState([])

  const columns = [
    columnHelper.accessor('accountId', {
      header: () => 'Account'
    }),
    columnHelper.display({
      id: 'createdDate',
      header: () => 'Created/Joined',
      cell: ({ row }) => {
        const { accountId } = row.original.rawRowData
        const account = allAccounts.find((item) => item.accountId === accountId)

        let formattedDate = ''
        if (account) {
          formattedDate = account.createdDate ? moment(account.createdDate).format('MM-DD-YYYY') : ''
        }

        return formattedDate
      }
    }),

    columnHelper.accessor('environment', {
      header: () => 'Environment'
    }),
    columnHelper.display({
      id: 'action',
      header: () => 'Action',
      cell: ({ row }) => {
        return (
          <div className="text-center">
            {allocationYear >= currentYear && (
              <button
                className="btn btn-sm btn-remap"
                onClick={() => {
                  onRemap(row.original.rawRowData)
                }}
              >
                Remap
              </button>
            )}
            <button
              {...{
                onClick: row.getToggleExpandedHandler()
              }}
              className="btn btn-sm btn-detail "
            >
              Details{' '}
              {row.getIsExpanded() ? (
                <FontAwesomeIcon icon={faChevronDown} />
              ) : (
                <FontAwesomeIcon icon={faChevronRight} />
              )}
            </button>
          </div>
        )
      }
    })
  ]

  useEffect(() => {
    const items = filteredAccounts.map((account) => {
      const { mappings } = account
      const latestMapping = mappings[mappings.length - 1]

      const selectedAccount = allAccounts.find((item) => item.accountId === account.accountId)

      if (selectedAccount) {
        account.accountName = selectedAccount.accountName
      }

      return {
        id: account.id,
        accountId: getAccountNameWithNumber(account),
        po: null,
        rawRowData: account,
        environment: latestMapping.accountType
      }
    })
    setData(items)
  }, [allAccounts, filteredAccounts])

  useEffect(() => {
    if (searchText) {
      const trimedSearchText = searchText.trim()
      const filteredItems = accountsWithAllocations.filter((item) => {
        if (
          item.accountId.indexOf(trimedSearchText) > -1 ||
          item.accountName?.toLowerCase().indexOf(trimedSearchText.toLowerCase()) > -1
        ) {
          return true
        }

        const { mappings } = item

        for (const { allocations } of mappings) {
          for (const allocation of allocations) {
            if (
              allocation.element1Id.toLowerCase().indexOf(trimedSearchText.toLowerCase()) > -1 ||
              allocation.element2Id.toLowerCase().indexOf(trimedSearchText.toLowerCase()) > -1 ||
              allocation.element3Id.toLowerCase().indexOf(trimedSearchText.toLowerCase()) > -1 ||
              allocation.element4Id.toLowerCase().indexOf(trimedSearchText.toLowerCase()) > -1
            ) {
              return true
            }
          }
        }

        return false
      })
      setFilteredAccounts(filteredItems)
    } else {
      setFilteredAccounts(accountsWithAllocations)
    }
  }, [accountsWithAllocations, searchText])

  const onSearchKey = (event) => {
    const { value } = event.target
    setSearchText(value)
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })

  return (
    <div className="simple-card">
      <h2>Allocation List</h2>
      <Row className="mb-2">
        <Col md={4}>
          <input
            type="text"
            value={searchText}
            className="form-control"
            placeholder="Search by Account or Financial Scope"
            onChange={onSearchKey}
          />
        </Col>
        <Col md={8}>{getPaginationControls(table)}</Col>
      </Row>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <button
                        {...{
                          className: header.column.getCanSort()
                            ? 'd-flex justify-content-between align-items-center user-select-none'
                            : '',
                          onClick: header.column.getToggleSortingHandler()
                        }}
                        className="sorting-button"
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <FontAwesomeIcon className="ml-2" icon={faArrowUp} />,
                          desc: <FontAwesomeIcon className="ml-2" icon={faArrowDown} />
                        }[header.column.getIsSorted()] ?? null}
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <Fragment key={row.id}>
                <tr>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                  ))}
                </tr>
                {row.getIsExpanded() && (
                  <tr className="expanded-row">
                    <td colSpan={row.getVisibleCells().length}>
                      <SubComponentTables
                        account={allAccounts.find((item) => item.accountId === row.original.rawRowData.accountId)}
                        allocations={row.original.rawRowData.mappings}
                        sorElements={sorElements}
                        sorElementMapping={sorElementMapping}
                      />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
      {getPaginationControls(table)}
    </div>
  )
}

const SubComponentTables = ({ account, allocations, sorElements, sorElementMapping }) => {
  const columns = [
    columnHelper.accessor('businessUnit', {
      header: () => sorElementMapping.element1
    }),
    columnHelper.accessor('department', {
      header: () => sorElementMapping.element2
    }),
    columnHelper.accessor('portfolio', {
      header: () => sorElementMapping.element3
    }),
    columnHelper.accessor('product', {
      header: () => sorElementMapping.element4
    }),
    columnHelper.accessor('percentage', {
      header: () => 'Percentage'
    }),
    columnHelper.accessor('reference', {
      header: () => 'Reference'
    }),
    columnHelper.accessor('effectiveDates', {
      header: () => 'Effective Dates'
    })
  ]

  const createdDate = moment(account.createdDate)
  return allocations.map((item, index) => {
    const isCurrentApplied = moment().isBetween(item.startDate, item.endDate)
    const currentYear = moment().year()
    const startDate = item.startDate

    const data = item.allocations.map((allocationItem) => {
      let effectiveDates = ''
      if (index === 0) {
        effectiveDates = `${createdDate.year() === currentYear ? createdDate.format('MM-DD-YYYY') : item.startDate} - ${
          item.endDate || 'Till-Date'
        }`
      } else {
        effectiveDates = `${startDate} - ${item.endDate || 'Till-Date'}`
      }
      return {
        businessUnit: sorElements.elementList1.find((item) => item.id === allocationItem.element1Id).value,
        department: sorElements.elementList2.find((item) => item.id === allocationItem.element2Id).value,
        portfolio: sorElements.elementList3.find((item) => item.id === allocationItem.element3Id).value,
        product: sorElements.elementList4.find((item) => item.id === allocationItem.element4Id).value,
        percentage: allocationItem.percentage,
        effectiveDates,
        reference: item.reference
      }
    })
    return (
      <DataTable
        columns={columns}
        data={data}
        className={isCurrentApplied ? 'current-allocations' : ''}
        updateDate={item.insertedDate}
      />
    )
  })
}

const DataTable = ({ columns, data, className, updateDate }) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel()
  })

  return (
    <div className="table-responsive">
      <table className={`table ${className}`}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
        {updateDate && (
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <div className="updated-at">
                  Updated <span>{moment.utc(updateDate).local().format('MM-DD-YYYY hh:mm:ss A')}</span>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  )
}

export default AccountsWithBudgets
