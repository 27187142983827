import listSORData from '../use-cases/list-sor-data-use-case'
import Editor from './Editor/Editor'
import UploadFile from './UploadFile'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from 'components/common/LoadingSpinner'
import CustomToggle from 'components/Toggle/CustomToggle'
import { Dropdown, Tab, Tabs } from 'react-bootstrap'
import { toast } from 'react-toastify'
import React, { useEffect, useState } from 'react'

const currentYear = new Date().getFullYear()

const Budget = ({ repoFactory }) => {
  const [availableYears, setAvailableYears] = useState([
    currentYear - 2,
    currentYear - 1,
    currentYear,
    currentYear + 1,
    currentYear + 2
  ])
  const [selectedYear, setSelectedYear] = useState(currentYear)
  const [isLoading, setIsLoading] = useState(false)
  const [budgetData, setBudgetData] = useState([])
  const [isNew, setIsNew] = useState(false)
  const [elementKeysData, setElementKeysData] = useState([])
  const [activeKey, setActiveKey] = useState('editor')

  const handleDataParsed = (data, elementKeys) => {
    setBudgetData(data)
    setElementKeysData(elementKeys)
    setIsNew(false)
  }

  useEffect(async () => {
    await listSORData(
      {
        token: localStorage.getItem('authToken'),
        effectiveYear: selectedYear
      },
      {
        sorRepo: repoFactory.sorRepo(),
        observer: {
          error: (e) => {
            setIsLoading(false)
          },
          success: (result) => {
            setIsLoading(false)
            setBudgetData(result.data)
            setElementKeysData(result.elementKeys)
            setActiveKey('editor')
            setIsNew(false)
            if (result.data.length === 0) {
              toast.info(`No budgets are set for ${selectedYear}. Please either upload file or start below.`)
              setIsNew(true)
            }
          }
        }
      }
    )
  }, [selectedYear])

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} icon={<FontAwesomeIcon icon={faCalendarAlt} />} id="menu-year-selection">
              {selectedYear}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {availableYears.map((mapYear) => {
                return (
                  <Dropdown.Item
                    key={mapYear}
                    active={mapYear === selectedYear}
                    eventKey={mapYear}
                    onClick={() => {
                      setSelectedYear(mapYear)
                    }}
                  >
                    {mapYear}
                  </Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Tabs className="tabs-section" activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
            <Tab eventKey="editor" title="Editor">
              <Editor
                repoFactory={repoFactory}
                setIsLoading={setIsLoading}
                budgetData={budgetData}
                elementKeysData={elementKeysData}
                setBudgetData={setBudgetData}
                selectedYear={selectedYear}
                isNew={isNew}
              />
            </Tab>

            <Tab eventKey="upload_file" title="Upload File">
              <UploadFile
                repoFactory={repoFactory}
                setIsLoading={setIsLoading}
                onDataParsed={handleDataParsed}
                selectedYear={selectedYear}
              />
            </Tab>
          </Tabs>
        </>
      )}
    </>
  )
}

export default Budget
